import React, {FC, InputHTMLAttributes, useCallback} from 'react';
import './Button.css';
import Button from '@mui/base/Button';
import {AnyAction} from "redux";

interface ButtonProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    disabled: boolean;
    active?: boolean;
    onClick?(): void;
}

const CustomButton: FC<ButtonProps> = ({name,disabled,active, onClick}) => {
    return (
        <Button className={`${active ? 'active-btn' : 'hidden-btn'}`} onClick={onClick} disabled={disabled}>{name}</Button>
    );
};

export default CustomButton;
