import {onChangeTaskStatus} from "../redux/actionCreators";

class ApiError extends Error {
    httpCode: number;

    constructor(httpCode: number, message: string) {
        super(message);
        this.httpCode = httpCode;
    }
}

export const get = (url:string) => fetch(url).then(handleResponse);

export const destroy = (url:string) => {
    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(handleResponse);
};

export const post = (url:string, data = {}) => {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(handleResponse);
};

export const put = (url:string, data = {}) => {
    return fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(handleResponse);
};


export const handleResponse = async (response:any) => {
    const contentType = response.headers.get('Content-Type');
    let body;
    switch (contentType) {
    case 'application/json':
    case 'application/vnd.spring-boot.actuator.v3+json':
    case 'application/octet-stream':
        body = await response.json();
        break;
    case 'text/html':
        body = await response.text();
        break;
    default:
        body = await response.body;
    }
    if (response.ok) {
        return body;
    } else {
        const message = contentType === 'application/json' ? body.message : body;
        throw new ApiError(response.status, message);
    }
};


export const getArtworkGuides = () => {
    const url = `/api/kdpl-preflight-service/artworkGuides`;
    return get(url);
};

export const getReportByName = (filename:string) => async () => {
    try {
        const url = `/api/kdpl-preflight-service/reports/${filename}`;
        const file =  await fetch(url).then(res=>res.clone().json());
        return file;
    } catch (error) {
        console.error(error);
    }
};
export const getReportFileByName = (filename:string) => async () => {
    try {
        const url = `/api/kdpl-preflight-service/reports/${filename}`;
        const file =  await fetch(url).then((response) => response.blob());
        return file;
    } catch (error) {
        console.error(error);
    }
};


export const getTaskStatus = () => async (dispatch:any) => {
    try {
        const url = `/api/kdpl-preflight-service/taskStatus`;
        await fetch(url).then(res=>res.clone().json()).then( status => dispatch(onChangeTaskStatus(status)));
    } catch (error) {
        console.error(error);
    }
};

export const getResult = () => async () => {
    try {
        const url = `/api/kdpl-preflight-service/resources/getResult`;
        const result =  await fetch(url).then(res=>res.clone().json());
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const refreshSession = () => {
    const url = `/api/kdpl-preflight-service/sessions/refreshSession`;
    return post(url);
};
export const destroySession = () => {
    const url = `/api/kdpl-preflight-service/sessions/destroySession`;
    return destroy(url);
};

export const deleteSession = () => async () => {
    try {
        await destroySession();
    } catch (error) {
        console.error(error);
    }
};

export const updateSession = () => async () => {
    try {
        await refreshSession();
    } catch (error) {
        console.error(error);
    }
};

export const uploadArtworkWithParams = (settings:FormData) => async () => {
    try {
        await postFormData(settings);
    } catch (error) {
        console.error(error);
    }
};

export const postFormData = (data = {}) => {
    const url = `/api/kdpl-preflight-service/resources/uploadFile`;
    return fetch(url, {
        method: 'POST',
        body: data as any,
    }).then(handleResponse);
};