import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import _ from "lodash";
import {useDropzone} from 'react-dropzone';
import {DropZoneProps} from './IDropzone';
import {deleteSession, updateSession, uploadArtworkWithParams} from "../../api/api";
import {getUserInfo} from "../../redux/reducers/userInfoReducer";
import {onChangeTaskStatus} from "../../redux/actionCreators";
import "./Dropzone.css";

const DropZone: FC<DropZoneProps> = ({disabled, onFileUpload}) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const userInfo = useSelector(getUserInfo);

    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.forEach((file: any) => {
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = async () => {
                onFileUpload();
                dispatch(onChangeTaskStatus( {status: 'uploading'}));
                await dispatch(deleteSession());
                await dispatch(updateSession());

                const data = new FormData();

                data.set('file', file, file.name);
                data.set('shape', userInfo.shape.value);
                data.set('width', userInfo.width);
                data.set('height', userInfo.height);

                await dispatch(uploadArtworkWithParams(data));
                dispatch(onChangeTaskStatus( {status: 'processing'}));
            };
            reader.readAsArrayBuffer(file);
        });
    }, [userInfo]);
    const supportedFileSize = _.isUndefined(process.env.REACT_APP_MAX_ARTWORK_SIZE) ? 20 : +process.env.REACT_APP_MAX_ARTWORK_SIZE;
    const {getRootProps, getInputProps, fileRejections} = useDropzone({
        onDrop,
        disabled: disabled,
        accept: {
            'image/jpeg': [ '.jpg', '.jpeg' ],
            'image/png': [],
            'image/tiff': [],
            'image/vnd.adobe.photoshop':['.psd'],
            'application/pdf': [],
            'application/x-photoshop': [],
            'application/photoshop': [],
            'application/psd': [],
            'application/postscript': []
        },
        maxSize: supportedFileSize * 1024 * 1024
    });

    const supportedFileFormats = '.pdf, .eps, .psd, .jpeg (.jpg), .png, .tiff, .ai';

    const fileRejectionItems = fileRejections.map(({file, errors}) => (
        <div key={file.name}>
            {errors.map(e => (
                <span key={e.code} about={file.type}>{e.code === 'file-too-large' ?
                    `File size must be less than ${supportedFileSize} MB`: `File type must be ${supportedFileFormats}`}
                </span>
            ))}
        </div>
    ));

    return (
        <div className={`dropzone-container ${disabled ? `disabled-dropzone` : `enabled-dropzone`}`}>
            <section {...getRootProps()}>
                <input name='dropzone' type='file' {...getInputProps()} />
                <div>Drop a file, or click to select a file (max size {supportedFileSize} MB)</div>
                <div>{`Supported file formats: ${supportedFileFormats}`}</div>
                <div className='dropzone-error-message'>{fileRejectionItems}</div>
            </section>
        </div>
    );
};

export default DropZone;