import * as React from "react";
import {ReportProblem} from '@mui/icons-material';
import Pluralize from "pluralize";
import {useDispatch} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../redux";
import {IError} from "./IError";
import './Error.css';

const Error: React.FC<IError> = (props) => {
    const dispatch = useDispatch();
    const {setSelectedError} = bindActionCreators(actionCreators, dispatch);
    const className = `error-item ${props.type} 
    ${props.active ? props.type === 'warning' ? 'selected-warning' : 'selected-error' : ''}`;

    const selectError = (e:any) =>{
        e.stopPropagation();
        setSelectedError(props.id);
    };

    return (
        <div className={className} onClick={selectError}>
            <ReportProblem/>
            <div className="error-short-description">{props.name}{props.hits !== 1 ? ` (${Pluralize('times', props.hits, true)})` : ''}</div>
        </div>
    );
};
export default Error;