import _ from "lodash";

export const convertJson = (setResultInfo: any,data: any) => {
    const isLoaded= true;
    const numErrors = data.result.numErrors;
    const numWarnings = data.result.numWarnings;
    let Bboxs: object[] = [];
    const checks = _.map(data.result.checks, check => {
        const type = check.severity === 3 ? 'error' : 'warning';
        _.map(check.hits, hit => {
            if(! _.isUndefined(hit.llx)){
                Bboxs = [...Bboxs, { id: check.id, llx: hit.llx, lly: hit.lly, urx: hit.urx, ury: hit.ury, width: hit.urx - hit.llx, height: hit.ury - hit.lly, type: type,
                    active: false}];
            }
        });
        return {
            id: check.id,
            name: check.name,
            comment: check.comment,
            type: type,
            hits: check.hits.length,
            active: false
        };
    });
    setResultInfo({isLoaded, numErrors, numWarnings, checks, Bboxs});
};