import * as React from "react";
import {FC, useCallback, useEffect, memo, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select from 'react-select';
import {useSearchParams} from 'react-router-dom';
import _ from "lodash";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import {bindActionCreators} from 'redux';
import {ThunkDispatch} from "@reduxjs/toolkit";
import {actionCreators} from '../../redux';
import DropZone from "../dropzone/Dropzone";
import {getUserInfo} from "../../redux/reducers/userInfoReducer";
import {getLoadedStatus, getTaskResultStatus} from "../../redux/reducers/resultInfoReducer";
import ResultPage from "../resultPage/ResultPage";
import CustomInput from "../input/Input";
import {convertJson} from "../../convertJson";
import {IPreviewImage, ShapeEL} from "./IUploadPage";
import {Shape, ShapeLabel} from "../../enums/Enums";
import {OrNull} from "../../types/generic";
import {getReportByName, getReportFileByName, getResult, getTaskStatus} from "../../api/api";
import {onChangeTaskStatus, setResultInfo} from "../../redux/actionCreators";
import './UploadPage.css';
import CustomButton from "../button/Button";

const ShapeOptions: ShapeEL[] = [
    {value: Shape.Rectangle, label: ShapeLabel.Rectangle, disabledHeight: false},
    {value: Shape.Square, label: ShapeLabel.Square, disabledHeight: true},
    {value: Shape.Circle, label: ShapeLabel.Circle, disabledHeight: true},
    {value: Shape.Oval, label: ShapeLabel.Oval, disabledHeight: false}
];

const EMPTY_PREVIEW: IPreviewImage = {
    imageUrl: '',
    originHeight: 0,
    originWidth: 0
};

const UploadPage: FC = () => {
    const userInfo = useSelector(getUserInfo);

    const userShape = userInfo.shape;
    const userWidth = userInfo.width;
    const userHeight = userInfo.height;
    const dispatch = useDispatch();
    const dispatchThunk = useDispatch<ThunkDispatch<any, any, any>>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [result, setResult] = useState({
        preflightedFile:'', previewImage:'', report:'', status:''});
    const [resultError, setResultError] = useState({error:'', status:''});
    const [preflightedFile, setPreflightedFile] = useState('');
    const [previewImage, setPreviewImage] = useState<IPreviewImage>(EMPTY_PREVIEW);

    const {onChangeShape, onChangeWidth, onChangeHeight, onChangeLoadingStatus, setResultInfo, onChangeTaskStatus} = useMemo(
        () => bindActionCreators(actionCreators, dispatch),
        [dispatch]
    );

    const handleSelectShapeChange = useCallback((selectedOption: OrNull<ShapeEL>) => {
        onChangeShape(selectedOption);
        if (selectedOption?.disabledHeight) {
            onChangeHeight(userWidth);
        }
    }, [onChangeShape, onChangeHeight, userWidth]);

    useEffect(() => {
        if (userShape?.disabledHeight) {
            onChangeHeight(userWidth);
        }
    }, [userWidth, userShape, onChangeHeight]);

    const SelectShape = () => (
        <div className='select-shape'>
            <label className='select-label'>Shape</label>
            <Select options={ShapeOptions}
                placeholder="Select shape"
                classNamePrefix='select-shape-of'
                isDisabled={taskStatus.status !== 'error' && taskStatus.status !== ''}
                value={userShape}
                onChange={handleSelectShapeChange}
            />
        </div>
    );
    const MemoizedSelectShape = memo(SelectShape);

    useEffect(() =>{
        const width: any = !_.isNull(searchParams.get('width')) && isFinite(Number(searchParams.get('width'))) ? searchParams.get('width') : '';
        const height: any = !_.isNull(searchParams.get('height')) && isFinite(Number(searchParams.get('height'))) ? searchParams.get('height') : '';
        const shape: any = _.find(ShapeOptions, option => option.value === searchParams.get('shape')) || null;

        onChangeWidth(width);
        onChangeHeight(height);
        onChangeShape(shape);
    }, []);

    useEffect(() =>{
        const shape = (_.isNull(userShape) || _.isNull(userShape.value)) ? null : {shape: userShape.value};
        const width = userWidth === '' ? '' : {width: userWidth};
        const height = userHeight === '' ? '' : {height: userHeight};

        const params = _.assign({}, shape, width, height);
        setSearchParams(params);
    }, [userShape, userWidth, userHeight, setSearchParams]);

    const taskStatus = useSelector(getTaskResultStatus);
    const loadedStatus = useSelector(getLoadedStatus);

    useEffect(() => {
        let intervalId:any;
        if(taskStatus.status === 'processing' || taskStatus.status === 'pending' ){
            intervalId = setInterval(() => {
                dispatchThunk(getTaskStatus());
            }, 500);
        }else if(taskStatus.status === 'completed'){
            clearInterval(intervalId);
            dispatchThunk(getResult()).then( result  => setResult(result));
        }else if(taskStatus.status === 'failed'){
            dispatchThunk(getResult()).then( result  => setResultError(result));
            clearInterval(intervalId);
        }
        return () => clearInterval(intervalId);
    }, [taskStatus]);

    useEffect(() => {
        const fetchData = async () => {
            if(taskStatus.status === 'completed'){
                await dispatchThunk(getReportByName(result.report)).then( result  => convertJson(setResultInfo, result));
                if(!_.isUndefined(result.preflightedFile)){
                    await dispatchThunk(getReportFileByName(result.previewImage)).then( (blob) => {
                        const img = new Image();
                        const imageUrl = URL.createObjectURL(blob as Blob);
                        img.src = imageUrl;
                        img.onload = () => {
                            const { naturalWidth: width, naturalHeight: height } = img;
                            setPreviewImage({ imageUrl:imageUrl,
                                originHeight:height,
                                originWidth:width});
                        };
                    });

                    await dispatchThunk(getReportFileByName(result.preflightedFile)).then( (blob) => {
                        const fileUrl = URL.createObjectURL(blob as Blob);
                        setPreflightedFile(fileUrl);
                    });

                }else{
                    await dispatchThunk(getReportFileByName(result.previewImage)).then( (blob) => {
                        const imgError = new Image();
                        const imageErrorUrl = URL.createObjectURL(blob as Blob);
                        imgError.src = imageErrorUrl;
                        imgError.onload = () => {
                            const { naturalWidth: width, naturalHeight: height } = imgError;
                            setPreviewImage({ imageUrl:imageErrorUrl,
                                originHeight:height,
                                originWidth:width});
                        };
                    });
                }
                await  onChangeLoadingStatus(true);
            }
        };
        fetchData().catch(console.error);
    },[result]);
    const {setSelectedError} = bindActionCreators(actionCreators, dispatch);
    const loadLineStyle = taskStatus.status === 'uploading' || taskStatus.status === 'processing' ||  taskStatus.status === 'pending' ? 'active-loading-line': "disabled-loading-line";
    const resetUserValues = () => {
        onChangeTaskStatus({status: ''});
        onChangeWidth('');
        onChangeHeight('');
        onChangeShape(null);
    };

    const onCheckAnother = () =>{
        onChangeLoadingStatus(false);
        onChangeTaskStatus( {status: ''});
        onChangeWidth('');
        onChangeHeight('');
        onChangeShape(null);
    };

    const removePreviousFile = () => {
        setPreviewImage(EMPTY_PREVIEW);
    };

    return (
        <div className="app" onClick={() => setSelectedError('')}>
            <header className="app-header" onClick={onCheckAnother}>
                <h1>Preflight Artwork Check</h1>
            </header>
            <div className={`${loadedStatus ? `disabled-upload-page` : `enabled-upload-page app-wrapper`}`}>
                <h2>Welcome to our Preflight Artwork Check</h2>
                <h3>Please choose your shape, width and height for the artwork you want to check, then upload your artwork to start verification.
                    Before uploading, ensure all Trim Marks, Registration Marks, Colour Bars, Page Information and any blank space is removed from the artwork.
                </h3>
                <main>
                    <div className="user-info">
                        <MemoizedSelectShape/>
                        <CustomInput name='width' label={!_.isNull(userShape) && userShape.disabledHeight ? 'Width and Height (mm)' : 'Width (mm)'} onChange={onChangeWidth} value={userWidth}
                            disabled={taskStatus.status !== 'error' && taskStatus.status !== ''} hidden={false}/>
                        <CustomInput name='height' label='Height (mm)' value={userHeight}
                            disabled={taskStatus.status !== 'error' && taskStatus.status !== ''}
                            hidden={!_.isNull(userShape) && userShape.disabledHeight}
                            onChange={onChangeHeight}/>
                    </div>
                    <div className="load-doc">
                        <div>Upload artwork</div>
                        <div>First specify shape and size, then upload your artwork</div>
                        <DropZone
                            disabled={!(userWidth !== '' && userHeight !== '' && !_.isNull(userShape) && !_.isNull(userShape.value)) || !(taskStatus.status === 'error' || taskStatus.status === '')}
                            onFileUpload={removePreviousFile} />
                        <div className="loading-status">
                            <div>{(taskStatus.status === '') ? '' : (`Status: ${taskStatus.status === 'pending' || taskStatus.status === 'completed' ? 'processing' : taskStatus.status}`)}</div>
                            <div>{taskStatus.status === 'failed' ? `${resultError.error}` : ''}</div>
                            <CustomButton name='Check another' disabled={false} active={taskStatus.status === 'failed'} onClick={resetUserValues}/>
                        </div>
                        <div className={`load-line ${loadLineStyle}`}>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress color="inherit"/>
                            </Box>
                        </div>
                    </div>
                </main>
            </div>
            <div className={`result-page-${loadedStatus ? 'loaded app-wrapper' : 'unloaded'}`}>
                <ResultPage preflightedFile={preflightedFile} preflightedFileName={!_.isUndefined(result.preflightedFile) ? result.preflightedFile.substring(result.preflightedFile.indexOf('W')) : 'Template'} previewImage={previewImage}/>
            </div>
        </div>
    );
};

export default UploadPage;