import { ActionType } from "../actionTypes";
import { Action } from "../actions";

const initialState = {
    shape: {value: '', label: '', disabledHeight: false},
    width: '',
    height: ''
};

const reducer = (state = initialState, action: Action): any => {
    switch (action.type){
    case ActionType.CHANGE_SHAPE:
        return {...state, shape: action.payload};
    case ActionType.CHANGE_WIDTH:
        return {...state, width: action.payload};
    case ActionType.CHANGE_HEIGHT:
        return {...state, height: action.payload};
    default: return state;
    }
};

export const getUserInfo = (state: any) => state.userInfo;

export default reducer;