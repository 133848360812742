import * as React from "react";
import {Verified} from '@mui/icons-material';
import {useEffect, useRef, useState} from "react";
import {ISuccessResult} from "./ISuccessResult";
import './SuccessResult.css';

const SuccessResult: React.FC<ISuccessResult> = (props) => {
    const artworkResult = useRef<any>(null);
    const [imgSize, setImgSize] = useState({width:0, height:0});

    useEffect( () => {
        if(artworkResult.current){
            const width  = artworkResult.current.offsetWidth;
            if(props.previewImage.originWidth > width){
                const scale = props.previewImage.originWidth / width;
                setImgSize({width:props.previewImage.originWidth / scale, height: props.previewImage.originHeight / scale});
            }else{
                setImgSize({width:props.previewImage.originWidth, height: props.previewImage.originHeight});
            }
        }
    }, [artworkResult, props.previewImage.originWidth]);

    return (
        <section className="success-result">
            <h3><Verified/>Preflight successful! Please still check your artwork.</h3>
            <article className="success-result-wrapper">
                <div className='success-message'>
                    <Verified/>
                    No obvious issues found, bleed and safe need manually checking.<br/>
                    Make sure to still check the visual below to verify your file has proper bleed and doesn’t have any text or other important objects in the safety area.
                </div>
                <div className="artwork-result"  ref={artworkResult}>
                    <img src={props.previewImage.imageUrl} alt="artwork" width={imgSize.width} height={imgSize.height}/>
                </div>
            </article>
        </section>
    );
};

export default SuccessResult;