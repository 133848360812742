import {ActionType} from "../actionTypes";
import {Action} from "../actions";
import {createSelector} from 'reselect';
import _ from "lodash";
import {IBbox} from "../../components/bbox/IBbox";

const initialState = {
    isLoaded: false,
    taskStatus: {status: ''},
    numErrors: 0,
    numWarnings: 0,
    checks: [],
    Bboxs: []
};

export interface ICheck {
    id: string;
    name: string;
    comment: string;
    type: string;
    hits: number;
    active: boolean;
}

const reducer = (state = initialState, action: Action): any => {
    switch (action.type) {
    case ActionType.CHANGE_LOADING_STATUS:
        return {...state, isLoaded: action.payload};
    case ActionType.CHANGE_TASK_STATUS:
        return {...state, taskStatus: action.payload};
    case ActionType.SET_RESULT_INFO:{
        return {...state, ...action.payload};
    }
    case ActionType.SET_ACTIVE_CHECK: {
        const checksWithSelection = _.map(state.checks, (check: ICheck) => {
            if (check.id === action.payload && !check.active) {
                return {...check, active: true};
            } else {
                return {...check, active: false};
            }
        });
        const BboxsWithSelection = _.map(state.Bboxs, (Bbox: IBbox) => {
            if (Bbox.id === action.payload && !Bbox.active)  {
                return {...Bbox, active: true};
            } else {
                return {...Bbox, active: false};
            }
        });
        return {...state, checks: checksWithSelection, Bboxs: BboxsWithSelection};
    }
    default: return state;
    }
};

export const getResultInfo = (state: any) => state.resultInfo;
export const getLoadedStatus = createSelector(getResultInfo, resultInfo => resultInfo.isLoaded);
export const getNumOfErrors = createSelector(getResultInfo, resultInfo => resultInfo.numErrors);
export const getNumOfWarnings = createSelector(getResultInfo, resultInfo => resultInfo.numWarnings);
export const getChecks = createSelector(getResultInfo, resultInfo => resultInfo.checks);
export const getBboxs = createSelector(getResultInfo, resultInfo => resultInfo.Bboxs);
export const getActiveCheck = createSelector(
    getChecks,
    checks => _.find(checks, check => check.active)
);
export const getTaskResultStatus = createSelector(getResultInfo, resultInfo => resultInfo.taskStatus);
export default reducer;
