import { Dispatch } from "redux";
import { ActionType } from "./actionTypes";
import { Action } from "./actions";

export const onChangeShape = (shape: object | null) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_SHAPE,
            payload: shape
        });
    };
};

export const onChangeWidth = (width: string) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_WIDTH,
            payload: width
        });
    };
};

export const onChangeHeight = (height: string) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_HEIGHT,
            payload: height
        });
    };
};

export const onChangeLoadingStatus = (isLoaded: boolean) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_LOADING_STATUS,
            payload: isLoaded
        });
    };
};

export const onChangeTaskStatus = (status: object) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_TASK_STATUS,
            payload: status
        });
    };
};
export const setSelectedError = (activeCheckId: string) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_ACTIVE_CHECK,
            payload: activeCheckId
        });
    };
};

export const setResultInfo = (resultInfo: object) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_RESULT_INFO,
            payload: resultInfo
        });
    };
};