import * as React from "react";
import {useDispatch} from "react-redux";
import CSS from "csstype";
import {IBbox} from "./IBbox";
import './Bbox.css';

const Bbox: React.FC<IBbox> = (props) => {
    const dispatch = useDispatch();
    const bboxStyles: CSS.Properties = {
        height: props.height + 'px',
        width: props.width + 'px',
        top: props.y + 'px',
        left: props.x + 'px'
    };
    const className = `bbox ${props.type} ${props.active ? 'selected' : ''}`;
    return (
        <div className={className} style = {bboxStyles}/>
    );
};
export default Bbox;