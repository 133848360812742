import * as React from "react";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import UploadPage from "./uploadPage/UploadPage";
import './App.css';

const App: React.FC = () => {
    const basePath = '';
    return (
        <BrowserRouter>
            <Routes>
                <Route path={basePath} element={<UploadPage/>}/>
                <Route
                    path="*"
                    element={<Navigate to={basePath} replace={true}/>}
                />
            </Routes>
        </BrowserRouter>
    );
};

export default App;