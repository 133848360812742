import {ReportProblem} from '@mui/icons-material';
import {useSelector} from "react-redux";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Pluralize from 'pluralize';
import _ from "lodash";
import {
    getActiveCheck,
    getChecks,
    getBboxs
} from "../../redux/reducers/resultInfoReducer";
import Error from "../error/Error";
import Bbox from "../bbox/Bbox";
import {IErrorResult} from "./IErrorResult";

import './ErrorResult.css';

const ErrorResult: React.FC<IErrorResult>  = (props) => {
    const checks = useSelector(getChecks);
    const numOfErrors = _.isUndefined(_.filter(checks, check => check.type === 'error')) ? 0 : _.filter(checks, check => check.type === 'error').length;
    const numOfWarnings = _.isUndefined(_.filter(checks, check => check.type === 'warning')) ? 0 : _.filter(checks, check => check.type === 'warning').length;
    const Bboxs = useSelector(getBboxs);
    const activeCheck = useSelector(getActiveCheck);
    const dpiCoefficient = _.isUndefined(process.env.REACT_APP_DPI) ? 300/72 : +process.env.REACT_APP_DPI/72;
    const artworkResult = useRef<any>(null);
    const artworkResultImg = useRef<any>(null);
    const [imgSize, setImgSize] = useState({width:0, height:0});
    const [bboxCoefficient, setbboxCoefficient] = useState(dpiCoefficient);

    const errorPartMessage = numOfErrors ? Pluralize('error', numOfErrors, true) : '';
    const warningPartMessage = numOfWarnings ? Pluralize('warning', numOfWarnings, true) : '';
    const errorMessage = `We found ${[errorPartMessage, warningPartMessage].filter((msg) => msg).join(' and ') || 'no errors'} in your artwork.
    Please review ${numOfErrors === 1 || numOfWarnings === 1 ? 'it' : 'the messages'} thoroughly to make sure these won't be a problem.
    You can click ${numOfErrors === 1 ? 'the error' : numOfWarnings === 1 ? 'the warning' : 'on any error or warning'} message to get more information about it.`;

    const errorType = activeCheck?.type || numOfErrors && 'error' || numOfWarnings && 'warning';
    const errorHeaderType = numOfErrors && 'error' || numOfWarnings && 'warning';
    const errorTypeClassName = errorType ? `${errorType}-message` : '';

    useEffect( () => {
        if(artworkResult.current){
            const width  = artworkResult.current.offsetWidth;
            if(props.previewImage.originWidth > width){
                const scale = props.previewImage.originWidth / width;
                setImgSize({width:props.previewImage.originWidth / scale, height: props.previewImage.originHeight / scale});
                setbboxCoefficient(dpiCoefficient / scale);
            }else{
                setImgSize({width:props.previewImage.originWidth, height: props.previewImage.originHeight});
                setbboxCoefficient(dpiCoefficient);
            }
        }
    }, [artworkResult, props.previewImage.originWidth]);

    return (
        <section className="error-result">
            <h3 className={errorHeaderType === 'error' ? 'result-type-error' : 'result-type-warning'}><ReportProblem/>{errorHeaderType === 'error' ? 'Preflight failed!' : 'Preflight warning!'}</h3>
            <article className="error-result-wrapper">
                <div className={errorTypeClassName}>
                    <ReportProblem/>
                    {activeCheck ? activeCheck.comment : errorMessage}
                </div>
                <div className="error-result-container">
                    <div className="error-list">
                        {_.map(checks, (check, index) =>{
                            return (<Error key = {check.id} id={check.id} comment={check.comment} name={check.name} type={check.type} hits={check.hits} active={check.active}/>);
                        })}
                    </div>
                    <div className="artwork-result"  ref={artworkResult}>
                        <img src={props.previewImage.imageUrl} alt="artwork" width={imgSize.width} height={imgSize.height} ref={artworkResultImg}/>
                        {artworkResultImg.current ? _.map(Bboxs, (bbox, bboxIndex) => {
                            return (<Bbox key = {bboxIndex} id={bbox.id} x={bbox.llx * bboxCoefficient} y={imgSize.height - (bbox.ury * bboxCoefficient)} height={bbox.height * bboxCoefficient} width={bbox.width * bboxCoefficient} type={bbox.type} active={bbox.active}/>);
                        }):''}
                    </div>
                </div>
            </article>
        </section>
    );
};
export default ErrorResult;