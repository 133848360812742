import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useMemo} from "react";
import _ from "lodash";
import SuccessResult from "../successResult/SuccessResult";
import ErrorResult from "../errorResult/ErrorResult";
import CustomButton from "../button/Button";
import {onChangeLoadingStatus, onChangeTaskStatus} from "../../redux/actionCreators";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../redux";
import {getUserInfo} from "../../redux/reducers/userInfoReducer";
import {getNumOfErrors, getNumOfWarnings} from "../../redux/reducers/resultInfoReducer";
import ArtWork_Guides from "../../Artwork_Guide.pdf";
import {Result} from "./IResultPage";
import './ResultPage.css';

const ResultPage:  React.FC<Result>  = (props) => {
    const userInfo = useSelector(getUserInfo);
    const numOfErrors = useSelector(getNumOfErrors);
    const numOfWarnings = useSelector(getNumOfWarnings);

    const dispatch = useDispatch();
    const {onChangeShape, onChangeWidth, onChangeHeight, onChangeLoadingStatus, onChangeTaskStatus} = useMemo(
        () => bindActionCreators(actionCreators, dispatch),
        [dispatch]
    );

    const artWorkDetails = `Artwork details: ${!_.isNull(userInfo.shape) ? userInfo.shape.value : ''} shape of ${userInfo.width}mm wide by ${userInfo.height}mm high.`;
    const onCheckAnother = () =>{
        onChangeLoadingStatus(false);
        onChangeTaskStatus( {status: ''});
        onChangeWidth('');
        onChangeHeight('');
        onChangeShape(null);
    };

    return (
        <div className="result-page">
            <h2>Preflight result</h2>
            <h3>
                <span className="line">Thank you for using our Preflight Artwork Check.</span>
                <span className="line">To ensure your artwork prints and cuts successfully and is not rejected, please make sure to still
                check the visual below to verify your file has proper <span style={{color: "#009de0"}}>bleed</span> and doesn't have any text or other important
                    objects in the <span style={{color: "#289548"}}>safety area</span> to avoid the risk of being cut off.</span>
            </h3>
            <div className="btns-wrapper">
                <div className="action-btns">
                    <a
                        href={props.preflightedFile}
                        download={props.preflightedFileName}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <CustomButton name='Download Template' disabled={_.isUndefined(props.preflightedFile) || props.preflightedFile === ''} active={true}/>
                    </a>
                    <a
                        href={ArtWork_Guides}
                        download="ArtWork_Guide"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <CustomButton name='Download Artwork Guide' disabled={false} active={true}/>
                    </a>
                </div>
                <CustomButton name='Check Another Artwork' disabled={false} active={true} onClick={onCheckAnother}/>
            </div>
            <h3>{artWorkDetails}</h3>
            <main>
                <div className='legend'>
                    <h4>Legend:</h4>
                    <div className='legend-param bleed'>Bleed</div>
                    <div className='legend-param safe-area'>Safe area</div>
                    <div className='legend-param cut-line'>Cut line</div>
                </div>
                {numOfErrors !== 0 || numOfWarnings !== 0 ?  <ErrorResult previewImage={props.previewImage}/> : <SuccessResult previewImage={props.previewImage}/>}
            </main>
        </div>
    );
};

export default ResultPage;
